<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="800">

      <v-card  :loading="isLoading">


        <v-overlay absolute :value="isLoading" opacity="0.8" color="white">
          <v-progress-circular indeterminate color="gifty"></v-progress-circular>
        </v-overlay>

        <v-card-title>
          Importer des comptes
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>
        <v-card-text class="pa-8">


          <v-stepper color="gifty" flat outlined class="mb-5" v-model="stepp">
            <v-stepper-header>

              <v-stepper-step color="gifty" step="1" :complete="stepp === 2">
                Choisir le fichier excel
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="gifty" step="2">
                BDD & Notifications
              </v-stepper-step>

            </v-stepper-header>
          </v-stepper>


          <div v-if="stepp === 1">

            <v-radio-group label="Sélectionnez le type d'identifiants de comptes traités dans le fichier excel *"
                           :error-messages="errors.accounts_type"
                           v-model="form.accounts_type">
              <v-radio label="Identifiants uniquement par email" value="emails"></v-radio>
              <v-radio label="Identifiants uniquement par numéro de téléphone" value="phones"></v-radio>
              <v-radio label="Les deux identifiants" value="both"></v-radio>
            </v-radio-group>

            <v-alert type="error" text v-if="fileErrors.length" dismissible>

              <div v-for="(errorGroup, index) in fileErrors" :key="index">
                <ul>
                  <li v-for="(error, subIndex) in errorGroup" :key="subIndex">
                    Ligne {{ error.row }} : {{ error.message }}
                  </li>
                </ul>
              </div>
            </v-alert>

            <v-file-input label="Fichier excel *"
                          ref="file"
                          chips
                          show-size
                          counter
                          small-chips
                          outlined dense
                          accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          :error-messages="errors.excel_file"
                          @change="uploadFile"></v-file-input>


            <div class="text-end">
              <v-btn :href="urlD"
                     color="gifty"
                     class="text-none v-btn--active"
                     text>
                <v-icon left>mdi-download</v-icon>
                Télécharger un exemplaire
              </v-btn>
            </div>


            <v-alert type="success" class="mt-5" text v-if="successUpload" prominent dense dismissible>
              Téléversement et traitement du fichier réussis !
              <br>
              Résultat : [ {{ totalAccounts }} ] comptes valides.
              <br>
              Cliquez sur "Suivant" pour continuer l'enregistrement de tous les comptes.
            </v-alert>

          </div>

          <div v-if="stepp === 2">

            <v-select
                v-model="form.database_id"
                :error-messages="errors.database_id"
                :items="databases"
                outlined
                dense
                item-text="name"
                item-value="id"
                hide-details="auto"
                label="Affecter à une base de données (optionnel)"
            />

            <v-checkbox
                v-model="form.password_need_change"
                :error-messages="errors.password_need_change"
                label="Le mot de passe doit être modifié"
                hint="Cochez cette case si l'utilisateur doit modifier son mot de passe à la première connexion."
                persistent-hint
            />

            <v-checkbox
                v-model="form.notification_mail"
                :error-messages="errors.notification_mail"
                label="Envoyer une notification par e-mail"
            ></v-checkbox>

            <v-select
                :disabled="!form.notification_mail"
                v-model="form.email_template_id"
                :error-messages="errors.email_template_id"
                :items="emailTemplates"
                outlined
                dense
                item-text="title"
                item-value="id"
                hide-details="auto"
                label="Sélectionner un modèle d'e-mail *"
            />

            <v-checkbox
                v-model="form.notification_sms"
                :error-messages="errors.notification_sms"
                label="Envoyer une notification par SMS"
            ></v-checkbox>

            <v-select
                :disabled="!form.notification_sms"
                v-model="form.sms_template_id"
                :error-messages="errors.sms_template_id"
                :items="smsTemplates"
                outlined
                dense
                item-text="title"
                item-value="id"
                hide-details="auto"
                label="Sélectionner un modèle de SMS *"
            />

            <v-radio-group v-model="form.mail_config_id"
                           :error-messages="errors.mail_config_id"
                           label="Sélectionner une configuration d'e-mail *"
            >
              <v-radio v-for="config in emailConfigs"
                       :key="config.id"
                       :label="config.mail_username"
                       :value="config.id"
              />
            </v-radio-group>
          </div>


        </v-card-text>
        <v-divider/>

        <v-card-actions>
          <v-spacer/>

          <v-btn @click="stepp -= 1"
                 v-if="stepp === 2"
                 color="primary"
                 text
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Retour
          </v-btn>
          &nbsp;

          <v-btn @click="stepp += 1"
                 v-if="stepp === 1"
                 :disabled="!successUpload"
                 color="primary"
                 depressed
          >
            Suivant
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>

          <v-btn @click="handleImport"
                 v-if="stepp === 2"
                 :loading="isLoading"
                 color="primary"
                 depressed
                 type="submit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Sauvgarder
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      stepp: 1,
      totalAccounts: 0,
      isLoading: false,
      successUpload: false,
      dialog: false,
      errors: {},
      fileErrors: [],
      form: {},
      urlD: process.env.VUE_APP_FILE_URL + '/accounts-example.xlsx',
      databases: [],
      emailTemplates: [],
      smsTemplates: [],
      emailConfigs: [],
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.errors = {}
      this.fileErrors = []

      if (this.$refs.file) {
        this.$refs.file.reset()
      }

      this.stepp = 1
      this.successUpload = false

      this.form = {
        accounts_type: "emails",
        excel_file: "",
        password_need_change: true,
        notification_mail: true,
        email_template_id: null,
        notification_sms: true,
        sms_template_id: null,
        mail_config_id: null,
        database_id: null,
      }
      this.fetchAllData()
    },
    close() {
      this.dialog = false
    },
    uploadFile(file) {
      if (file) {
        this.form.excel_file = file
        this.handleImport('one')
      }
    },
    handleImport(stepp) {
      this.successUpload = false;
      this.isLoading = true;
      this.errors = {};
      this.fileErrors = [];

      let formData = new FormData();
      formData.append('excel_file', this.form.excel_file);
      formData.append('accounts_type', this.form.accounts_type);
      formData.append('password_need_change', this.form.password_need_change ? '1' : '0');
      formData.append('notification_mail', this.form.notification_mail ? '1' : '0');
      formData.append('notification_sms', this.form.notification_sms ? '1' : '0');
      formData.append('mail_config_id', this.form.mail_config_id || '');
      formData.append('email_template_id', this.form.email_template_id || '');
      formData.append('sms_template_id', this.form.sms_template_id || '');
      formData.append('database_id', this.form.database_id || '');
      formData.append('stepp', stepp);

      HTTP.post("/v1/accounts/import", formData)
          .then((res) => {
            this.isLoading = false;
            this.successUpload = true;

            if (stepp === 'one') {
              this.totalAccounts = res.data.data
            } else {
              this.close()
              this.$emit('refresh')
              this.$successMessage = "Opération réussie!";
            }

          })
          .catch((err) => {
            this.isLoading = false;

            if (err.response && err.response.status === 422) {
              this.errors = err.response.data.errors;
            }

            if (err.response && err.response.status === 400) {
              this.fileErrors = err.response.data.fileErrors;
            }

            this.$errorMessage = "Une erreur s'est produite veuillez corriger.";

            console.log(err);
          });
    },
    async fetchAllData() {
      await Promise.all([
        this.fetchData("/v1/users/databases", "databases"),
        this.fetchData("/v1/email-templates", "emailTemplates"),
        this.fetchData("/v1/sms-templates", "smsTemplates"),
        this.fetchData("/v1/email-configs", "emailConfigs")
      ]);
    },

    async fetchData(endpoint, dataProperty) {
      try {
        const response = await HTTP.get(endpoint);
        this[dataProperty] = response.data.data;
      } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
      }
    },
  }
}
</script>

<style scoped>

</style>