<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card :loading="loadingData" :disabled="loadingData">

        <v-card-title>
          Renvoyer la notification
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-checkbox
              v-model="form.notification_mail"
              :error-messages="errors.notification_mail"
              label="Envoyer une notification par e-mail"
          ></v-checkbox>

          <v-select
              :disabled="!form.notification_mail"
              v-model="form.email_template_id"
              :error-messages="errors.email_template_id"
              :items="emailTemplates"
              outlined
              dense
              item-text="title"
              item-value="id"
              hide-details="auto"
              label="Sélectionner un modèle d'e-mail *"
          />

          <v-checkbox
              v-model="form.notification_sms"
              :error-messages="errors.notification_sms"
              label="Envoyer une notification par SMS"
          ></v-checkbox>

          <v-select
              :disabled="!form.notification_sms"
              v-model="form.sms_template_id"
              :error-messages="errors.sms_template_id"
              :items="smsTemplates"
              outlined
              dense
              item-text="title"
              item-value="id"
              hide-details="auto"
              label="Sélectionner un modèle de SMS *"
          />

          <v-radio-group v-model="form.mail_config_id"
                         :error-messages="errors.mail_config_id"
                         label="Sélectionner une configuration d'e-mail *"
          >
            <v-radio v-for="config in emailConfigs"
                     :key="config.id"
                     :label="config.mail_username"
                     :value="config.id"
            />
          </v-radio-group>
        </v-card-text>
        <v-divider/>

        <v-card-actions>

          <v-spacer/>

          <v-btn @click="resent"
                 :loading="isLoading"
                 color="primary"
                 depressed
                 type="submit"
          >
            <v-icon left>mdi-send</v-icon>
            Renvoyer
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      loadingData: false,
      dialog: false,
      errors: {},
      form: {},
      emailTemplates: [],
      smsTemplates: [],
      emailConfigs: [],
      selected_ids: []
    }
  },
  methods: {
    open(selected_ids) {
      this.selected_ids = selected_ids
      this.dialog = true
      this.errors = {}
      this.fileErrors = []

      this.form = {
        notification_mail: true,
        email_template_id: null,
        notification_sms: true,
        sms_template_id: null,
        mail_config_id: null,
      }
      this.fetchAllData()
    },
    close() {
      this.dialog = false
    },
    resent() {
      this.isLoading = true;
      this.errors = {};

      let data = this.form
      data['selected_ids'] = this.selected_ids

      HTTP.post("/v1/accounts/re-sent-notification", data)
          .then(() => {
            this.isLoading = false;
            this.close()
            this.$successMessage = "Opération réussie!";
          })
          .catch((err) => {
            this.isLoading = false;

            if (err.response && err.response.status === 422) {
              this.errors = err.response.data.errors;
            }
            this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
            console.log(err);
          });
    },
    async fetchAllData() {
      this.loadingData = true;
      try {
        await Promise.all([
          this.fetchData("/v1/users/databases", "databases"),
          this.fetchData("/v1/email-templates", "emailTemplates"),
          this.fetchData("/v1/sms-templates", "smsTemplates"),
          this.fetchData("/v1/email-configs", "emailConfigs")
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loadingData = false;
      }
    },
    async fetchData(endpoint, dataProperty) {
      try {
        const response = await HTTP.get(endpoint);
        this[dataProperty] = response.data.data;
      } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
      }
    }
  }
}
</script>

<style scoped>

</style>